import { Helmet } from 'react-helmet'
import React from 'react'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { theme } from '../../src/theme'
import { QueryClientRootProvider } from '../../src/providers/QueryClientRootProvider'
import { GA_MEASUREMENT_ID } from '../../src/config/api'

const meta = {
	name: 'SUPA – System zamówień online dla restauracji',
	description:
		'SUPA ⭐ oferuje innowacyjne rozwiązania dla restauracji, umożliwiając napiwki i płatności bezgotówkowe za pomocą menu z QR kodem.',
	image: 'https://supa.pl/supa.png',
	url: 'https://supa.pl',
}

export default function AppLayout(props) {
	return (
		<>
			<Helmet>
				<meta name="viewport" content="initial-scale=1, width=device-width" />
				{/* HTML */}
				<title>{meta.name}</title>
				<meta name="description" content={meta.description} />

				{/* GOOGLE */}
				<meta property="name" content={meta.name} />
				<meta property="description" content={meta.description} />
				<meta property="image" content={meta.image} />

				{/* FACEBOOK */}
				<meta property="og:type" content="website" />
				<meta property="og:title" content={meta.name} />
				<meta property="og:description" content={meta.description} />
				<meta property="og:image" content={meta.image} />
				<meta property="og:url" content={meta.url} />

				{/* TWITTER */}
				<meta name="twitter:title" content={meta.name} />
				<meta name="twitter:description" content={meta.description} />
				<meta name="twitter:card" content={meta.image} />
				<meta name="twitter:image" content={meta.image} />

				{/* GTAG */}
				<script
					async
					src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
				></script>
				<script>
					{`
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments)}
						gtag('js', new Date());
						gtag('config', '${GA_MEASUREMENT_ID}');
					`}
				</script>

				<noscript>
					{`
						<style>
							[data-sal|='fade'] {
								opacity: 1;
							}
							
							[data-sal|='slide'],
							[data-sal|='zoom'] {
								opacity: 1;
								transform: none;
							}

							[data-sal|='flip'] {
								transform: none;
							}
						</style>
					`}
				</noscript>
			</Helmet>
			<QueryClientRootProvider>
				<ThemeProvider theme={theme}>
					<StyledThemeProvider theme={theme}>
						<StyledEngineProvider injectFirst>
							<CssBaseline />
							{props.children}
						</StyledEngineProvider>
					</StyledThemeProvider>
				</ThemeProvider>
			</QueryClientRootProvider>
		</>
	)
}
